<template>
  <base-section id="about-our-product">
    <base-section-heading
      icon="mdi-account-group"
      title="We Are All The SA"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Buy Staking NFT',
          subtitle: 'A LIMITED NFT WHICH EARNS YIELD',
          text: 'You have to option to purchase an NFT from the Icosa contract which earns yield in ICSA during the limited "We Are All the SA" event. There is no set price, the price you choose determines the amount of stake points the NFT is allocated. Like HDRN staking, yield is determined by the amount of burnt HDRN each day.',
          callout: '01',
        },
        {
          title: 'No Dilution',
          subtitle: 'A PRIVATE POOL FOR NFT HOLDERS',
          text: 'After the event entry phase has ended, no more entries can be made into the staking pool. The percentage of the staking pool your NFT is allocated determines your percentage of the daily yield in perpetuity. If other NFT holders end their stakes and claim their yield, they cannot re-enter.',
          callout: '02',
        },
        {
          title: 'Yield Boost Mechanics',
          subtitle: 'A STAKERS DREAM',
          text: 'To end the stake and claim the yield, you must exit the staking pool. Because no new entries can be made into the pool, the percentage of pool allocation for all other NFT holders goes up when these stakes end. The last man standing earns 100% of pool yield.',
          callout: '03',
        },
      ],
    }),
  }
</script>
